import React, {useContext, useEffect, useState} from "react"
import { graphql } from "gatsby"
import { Breadcrumb } from "gatsby-plugin-breadcrumb"
import Layout from "../components/layout"
import SEO from '../components/seo'
import { PlayerContext, PODCAST_TYPE } from "../components/context/player-context"
import { secondToTime } from "../components/player"
import {Duration} from "../components/duration";

const PodcastTemplate = ({ pageContext, data }) => {
  const {
    breadcrumb: { crumbs },
  } = pageContext

  const playerContext = useContext(PlayerContext)
  const [_play, _setPlay] = useState(false)
  const { play, setPlay, audio, setEnabled, setContent, content } = playerContext

  const [duration, setDuration] = useState(undefined)

  useEffect(() => {
    if (content.type === PODCAST_TYPE && content.description === data.podcast.title && play) {
      _setPlay(true)
      audio.play()
    } else if (content.type === PODCAST_TYPE && content.description === data.podcast.title && !play) {
      audio.pause()
      _setPlay(false)
    } else {
      _setPlay(false)
    }
  }, [content, play, audio])

  const handleToggle = () => {
    if (audio.src !== window.location.toString().substring(0, window.location.toString().length - 1) + data.podcast.relationships.audio.localFile.publicURL) {
      audio.src = data.podcast.relationships.audio.localFile.publicURL
    }
    setContent({ type: PODCAST_TYPE, description: data.podcast.title })
    setPlay(content.description === data.podcast.title ? !play : true)
    setEnabled(true)
  }

  useEffect(() => {
    if(duration === undefined) {
      let podcast = new Audio(data.podcast.relationships.audio.localFile.publicURL)
      podcast.onloadedmetadata = (() => {
        setDuration(secondToTime(podcast.duration))
      });
    }
  }, [])

  return (
    <Layout>
      <SEO
          title={data.podcast.title}
          description={data.podcast.body.value}
      />
      <div className="container content-page">
        <section className="section page-header">
          <Breadcrumb
            crumbs={crumbs}
            crumbSeparator=" > "
            crumbLabel={data.podcast.title}
          />

          <h1 className="title is-1">{data.podcast.title}</h1>

          <div className="date">
            <span>{data.podcast.created}</span>
          </div>
        </section>

        <section className="section page-content">
          {data.podcast.body && (
            <div className="content" dangerouslySetInnerHTML={{ __html: data.podcast.body.value }}/>
          )}

          <div className="has-text-centered">
            <button onClick={handleToggle} className="button is-rounded is-primary has-icon">
              {_play ? (
                <>
                  <span className="icon icon-pause-o"></span>
                  <span className="icon-explain">Arréter la lecture</span>
                </>
              ) : (
                <>
                  <span className="icon icon-play-o"></span>
                  <span className="icon-explain">Lecture</span>
                </>
              )}
              {data.podcast.field_duree && (
                <>
                  &nbsp;(<Duration time={data.podcast.field_duree.seconds}/>)
                </>
              )}
            </button>
          </div>
        </section>
      </div>
    </Layout>
  )
}

export default PodcastTemplate

export const query = graphql`
  query($internalId: Int!) {
    podcast: nodePodcast(status: {eq: true}, drupal_internal__nid: { eq: $internalId }) {
      title
      field_duree {
        seconds
      }
      body {
        value
      }
      created(formatString: "DD MMMM YYYY", locale: "fr")
      relationships {
        audio : field_audio {
          localFile {
            publicURL
          }
        }
      }
    }
  }
`
